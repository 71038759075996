import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const IndexPage = () => {

  const classes = useStyles()

  return(
    <Layout>
      <SEO title="Home" />
      <h1>whowantsto.bike</h1>
      <h5>Rides, Routes, Trips, Ideas!</h5>
      <Divider />
      <List
        component="nav"
        className={classes.root}
      >
      </List>
    </Layout>
  )
}

export default IndexPage
